import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { graphql } from 'gatsby';
import sanitizeHtml from 'sanitize-html';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import PageWrapper from '../components/PageWrapper';
import { getPostAccountIcon } from '../utils';

interface IProps {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
        category: string;
        excerpt: string;
        date: string;
        imageDescription: string;
        coverImage: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
      };
    };
  };
}

const BlogPost: React.FC<IProps> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, category, excerpt, date, coverImage, imageDescription } =
    frontmatter;

  const sanitisedHtml = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'span']),
    allowedAttributes: false,
  });

  const pageTitle = `Blog - ${title}`;

  return (
    <PageWrapper>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={excerpt} />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center upper">{title}</h2>
            <div className="blog-post-source-icon">
              {getPostAccountIcon(category)}
            </div>
            <p className="text-center upper very-bold blog-post-date">{date}</p>
            <GatsbyImage
              className="blog-post-cover-img"
              image={coverImage.childImageSharp.gatsbyImageData}
              alt=""
            />
            {imageDescription}
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: sanitisedHtml }} />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlugNew(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 140)
      html
      frontmatter {
        category
        title
        date(formatString: "MMMM DD YYYY")
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default BlogPost;
